import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import "jquery";
import "easy-autocomplete";

document.addEventListener("turbolinks:load", () => {
  /*
  var options = {
    data: [
      "Food & Beverage",
      "Automotive",
      "Studi professionali",
      "Consulenza",
      "Arredo",
      "Ediilizia",
      "Trasporti e Logistica",
      "Software",
      "Marketing",
      "Sport",
      "Finanza",
      "Moda",
      "Tessile",
      "Facility management",
      "Packaging ",
      "Stampi industriali",
      "Sicurezza",
      "Eventi",
      "Cosmetica",
      "Energia Gas",
      "Turismo",
      "Macchinari industriali"
    ],
    list: {
      maxNumberOfElements: 22,
      match: {
        enabled: true
      }
    }
  };
  */
  let settings = {
    list: {
      maxNumberOfElements: 22,
      match: {
        enabled: true
      }
    }
  };

  // get the options from the data attribute of the input field
  const data = { data: $('*[data-behavior="autocomplete"]').data("options") }

  // merge the data with the default settingskkk
  //

  // add data to the settings
  if (data) {
    settings = $.extend(settings, data);
  }

  $('*[data-behavior="autocomplete"]').easyAutocomplete(settings).click(function(){
    $(this).triggerHandler(jQuery.Event("keyup", { keyCode: 65, which: 65}))
  });
});
