// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "bootstrap/js/src/tooltip";
import "bootstrap/js/src/tab";
import "bootstrap/js/src/collapse";
import "channels";
import "jquery";
import "packs/customer_sectors.js"
import "@nathanvda/cocoon"
import Trix from "trix"
require("@rails/actiontext")

document.addEventListener("turbolinks:load", () => {
  $(function() {
    $("#nav-tab a").on("click", function(e) {
      e.preventDefault();
      $(this).tab("show");
    });
    $("#nav-tab-table-chart a").on("click", function(e) {
      e.preventDefault();
      $(this).tab("show");
    });
  });
});

document.addEventListener("trix-before-initialize", () => {
  Trix.config.textAttributes.clear = {
    style: { color: "black" },
    parser: function(element) {
      return element.style.color === "black"
    },
    inheritable: true
  }

  Trix.config.textAttributes.red = {
    style: { color: "red" },
    parser: function(element) {
      return element.style.color === "red"
    },
    inheritable: true
  }

  Trix.config.textAttributes.green = {
    style: { color: "green" },
    parser: function(element) {
      return element.style.color === "green"
    },
    inheritable: true
  }
})
addEventListener("trix-initialize", function(event) {
  const clearButton = '<button type="button" class="trix-button bg-light" data-trix-attribute="clear">N</button>';
  const redButton = '<button type="button" class="trix-button bg-danger" data-trix-attribute="red">R</button>';
  const greenButton = '<button type="button" class="trix-button bg-success" data-trix-attribute="green">V</button>';

  event.target.toolbarElement.querySelector(".trix-button--icon-link").parentNode.insertAdjacentHTML("beforeend", clearButton)
  event.target.toolbarElement.querySelector(".trix-button--icon-link").parentNode.insertAdjacentHTML("beforeend", redButton)
  event.target.toolbarElement.querySelector(".trix-button--icon-link").parentNode.insertAdjacentHTML("beforeend", greenButton)
})

Rails.start();
Turbolinks.start();
ActiveStorage.start();
